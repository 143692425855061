<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-items' }">{{
            $t("ALL")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingItem"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <v-tabs
          background-color="white"
          color="primary accent-4"
          centered
          icons-and-text
        >
          <v-tab> {{ $t("ITEM") }} <v-icon>mdi-hamburger</v-icon></v-tab>

          <v-tab> {{ $t("STORES") }} <v-icon>mdi-home</v-icon></v-tab>

          <v-tab>
            {{ $t("SUPPLIERS") }}
            <v-icon>mdi-truck-fast</v-icon></v-tab
          >
          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col :lg="12">
                <b-form>
                  <FormBuilder
                    v-if="!firstLoader"
                    :form="form"
                    :schemaJson="schemaJson"
                    :v="$v"
                  ></FormBuilder>
                  <SkeletonLoaderMain
                    :visible="firstLoader"
                  ></SkeletonLoaderMain>
                </b-form>
              </b-col>
            </b-row>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col :lg="12">
                <CheckboxList
                  :heading="$t('STORES')"
                  :items="stores"
                  :selectedItems.sync="form.stores"
                  :modelName="'stores'"
                  :firstLoader="firstLoader"
                  :checkboxLoader="isLoadingSharedStore"
                  :labelKey="'nameWithCity'"
                  :sm="4"
                >
                </CheckboxList>
                <SkeletonLoaderCheckboxes
                  :visible="firstLoader"
                ></SkeletonLoaderCheckboxes>
              </b-col>
            </b-row>
          </v-tab-item>
          <v-tab-item :transition="false" :reverse-transition="false">
            <b-row>
              <b-col :lg="12">
                <CheckboxList
                  :heading="$t('SUPPLIERS')"
                  :items="suppliers"
                  :selectedItems.sync="form.suppliers"
                  :modelName="'suppliers'"
                  :firstLoader="firstLoader"
                  :checkboxLoader="isLoadingSharedSupplier"
                >
                </CheckboxList>
                <SkeletonLoaderCheckboxes
                  :visible="firstLoader"
                ></SkeletonLoaderCheckboxes>
              </b-col>
            </b-row>
          </v-tab-item>
        </v-tabs>
        <v-overlay :value="!firstLoader && isLoadingItem">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingItem"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_ITEM } from "@/modules/item/store/item.module";

import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";
import CheckboxList from "@/view/content/forms/components/CheckboxList.vue";
import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

//Service

import KTCard from "@/view/content/Card.vue";
import { mapGetters, mapState } from "vuex";

import _ from "lodash";

export default {
  mixins: [validationMixin, formBuilderMixin],
  data() {
    return {
      errors: {},
      firstLoader: true,
      item: {},
      itemTypes: [],
      suppliers: [],
      stores: [],
      units: []
    };
  },
  components: {
    KTCard,
    FormBuilder,
    SaveButtonDropdown,
    CheckboxList
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Items", route: { name: "list-items" } },
      { title: "Add item" }
    ]);
    vm.generateFormOutOfSchemaJson(this.schemaJson);
    vm.getSelects();
    vm.$nextTick(function() {
      vm.firstLoader = false;
    });
    vm.$set(vm.form, "suppliers", []);
    vm.$set(vm.form, "stores", []);
  },
  watch: {},
  computed: {
    ...mapGetters([
      "isLoadingItem",
      "isLoadingSharedSupplier",
      "isLoadingSharedStore",
      "currentUser"
    ]),
    ...mapState({
      errors: state => state.auth.errors
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "radio",
            groupId: "item-item-type-group",
            id: "item-item-type-input",
            inputType: "radio",
            feedback_id: "item-item-type-live-feedback",
            colSm: "3",
            divider: true,
            rowHorizontal: true,
            i18n: {
              label: "ITEM_TYPE",
              placeholder: "ITEM_TYPE"
            },
            options: this.itemTypes,
            validations: {
              required: true
            },
            model: "itemTypeId"
          },
          {
            type: "input",
            inputType: "number",
            id: "item-code-input",
            groupId: "item-code-group",
            required: "required",
            feedback_id: "item-code-live-feedback",
            divider: true,
            i18n: {
              label: "CODE",
              placeholder: "CODE",
              validation: "VALID_CODE"
            },
            validations: {
              required: true,
              minLength: 1
            },
            translatable: false,
            model: "code"
          },
          {
            type: "input",
            inputType: "text",
            id: "item-name-input",
            groupId: "item-name-group",
            required: "required",
            feedback_id: "item-name-live-feedback",
            divider: false,
            i18n: {
              label: "NAME",
              placeholder: "NAME",
              validation: "VALID_NAME"
            },
            validations: {
              required: true,
              minLength: 1
            },
            translatable: true,
            model: "name"
          },
          {
            type: "input",
            inputType: "number",
            id: "item-defaultCost-input",
            groupId: "item-defaultCost-group",
            required: "required",
            feedback_id: "item-defaultCost-live-feedback",
            divider: false,
            default: 0,
            i18n: {
              label: "DEFAULT_COST",
              placeholder: "DEFAULT_COST"
            },
            validations: {
              required: true,
              decimal: true,
              minValue: 0
            },
            translatable: false,
            model: "defaultCost"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-warehouse-group",
            id: "item-warehouse-input",
            feedback_id: "warehouse-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "ITEM_WAREHOUSE",
              placeholder: "ITEM_WAREHOUSE"
            },
            options: this.warehouses,
            validations: {
              required: true
            },
            model: "warehouseId"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-package-group",
            id: "item-package-input",
            feedback_id: "package-live-feedback",
            divider: true,
            clearable: false,
            i18n: {
              label: "ITEM_PACKAGE",
              placeholder: "ITEM_PACKAGE"
            },
            options: this.packages,
            validations: {
              required: true
            },
            model: "packageId"
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            feedback_id: "unit-live-feedback",
            divider: false,
            clearable: false,
            i18n: {
              label: "UNIT",
              placeholder: "UNIT"
            },
            options: this.units,
            validations: {
              required: true
            },
            model: "unitId"
          },
          {
            type: "input",
            inputType: "number",
            id: "item-unitValue-input",
            groupId: "item-unitValue-group",
            required: "required",
            feedback_id: "item-unitValue-live-feedback",
            divider: false,
            default: 0,
            i18n: {
              label: "UNIT_VALUE_IN_PACKAGE",
              placeholder: "UNIT_VALUE_IN_PACKAGE"
            },
            validations: {
              required: true,
              decimal: true,
              minValue: 0
            },
            translatable: false,
            model: "unitValue"
          },
          {
            type: "input",
            inputType: "number",
            id: "item-weight-input",
            groupId: "item-weight-group",
            required: "required",
            feedback_id: "item-weight-live-feedback",
            divider: false,
            default: "1.000",
            i18n: {
              label: "WEIGHT",
              placeholder: "WEIGHT"
            },
            validations: {
              required: true,
              decimal: true,
              minValue: 0
            },
            translatable: false,
            model: "weight"
          },
          {
            type: "input",
            inputType: "number",
            id: "days-for-order-input",
            groupId: "days-for-order-input-group",
            required: "required",
            feedback_id: "days-for-order-input-feedback",
            divider: false,
            default: 21,
            i18n: {
              label: "DAYS_FOR_ORDER",
              placeholder: "DAYS_FOR_ORDER"
            },
            validations: {
              required: true,
              decimal: true,
              minValue: 0
            },
            translatable: false,
            model: "daysForOrder"
          }
        ]
      };
    }
  },
  validations() {
    // let vm = this;
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );
    return tmpValidationObject;
  },
  methods: {
    transformUrlParams(params) {
      let apiParams =
        "?" +
        Object.keys(params)
          .map(key => key + "=" + params[key])
          .join("&");
      return apiParams;
    },
    getSelects() {
      let vm = this;
      let payload = {
        units: {
          fields: ["id", "name"],
          search: ""
        },
        item_types: {
          fields: ["id", "name"],
          search: ""
        },
        stores: {
          fields: ["id", "name", "nameWithCity"],
          search: ""
        },
        suppliers: {
          fields: ["id", "name"],
          search: ""
        },
        packages: {
          fields: ["id", "name"],
          search: ""
        },
        warehouses: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.units = data.data.units;
          vm.itemTypes = data.data.item_types;
          vm.stores = data.data.stores;
          vm.suppliers = data.data.suppliers;
          vm.packages = data.data.packages;
          vm.warehouses = data.data.warehouses;

          vm.$set(vm.form, "unitId", vm.units[0].id);
          vm.$set(vm.form, "itemTypeId", vm.itemTypes[0].id);
          vm.$set(vm.form, "packageId", vm.packages[0].id);
          vm.$set(vm.form, "warehouseId", vm.warehouses[0].id);
        })
        .catch(response => {
          console.log(response);
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit(evt) {
      evt.preventDefault();
    },
    onSave(next) {
      let vm = this;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }

      this.$store
        .dispatch(SAVE_ITEM, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-item",
              params: { id: itemEdit.id }
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-item" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-items" });
          }
        })
        .catch(function(response) {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: "Error",
            text: response.data.message
          });
        });
    },
    resetVForm() {
      this.$v.$reset();
    },
    handleStoreInput(storeData) {
      let vm = this;
      let storeIndex = _.findIndex(vm.form.stores, { id: storeData.storeId });
      if (storeIndex != -1) {
        vm.form.stores[storeIndex][storeData.storeItemData.prop] =
          storeData.storeItemData.value;
      }
    }
  }
};
</script>
